<template>
  <div class="component-box">
    <h3>解决方案构成  ·  component</h3>
    <div class="component-item" v-for="(item,index) in list" :key="index">
      <h3><span>0{{index+1}}</span>-{{item.name}}</h3>
      <p>{{item.remark}}</p>
    </div>
  </div>
</template>
<script>
export default {
  data () {
    return {
      list:[
        {name:'企业数据清洗',remark:'对企业数据资产进行全面盘点，形成数据地图，基于OneID、OneData、OneService的方法论，为企业建立统一的数据标识、数据服务能力标准，为企业下一步的数据集中、业务集中奠定基础。'},
        {name:'企业数据集成 ',remark:'运用阿里云强大的数据整合工具，帮助企业把不同来源、格式、特点性质的数据源在逻辑上或物理上进行集中，从而为企业提供更为全面的数据共享。'},
        {name:'数据可视化',remark:'基于阿里云的数据可视化平台（DataV）、商业智能分析平台（Quick BI），快速构建企业运营全景可视化大屏，结合行业及自身业务特点进行多维数据挖掘与分析。'},
        {name:'应用场景开发',remark:'支持多应用场景的企业数据可视化呈现，企业数字化运营分析报表可以与PC、APP、钉钉、⼩程序深度结合，实现业务灵活性、且高度可定制化。'},
      ]
    }
  }
}
</script>
<style lang="less" scoped>
.component-box{
  padding: 50px 15px;
  color: #383838;
  >h3{
    text-transform: uppercase;
    font-size: 18px;
    font-weight: bold;
    text-align: center;
    margin-bottom: 40px;
  }
  .component-item{
    border: 1px solid #E2E2E2;
    padding: 20px;
    background-color: #fff;
    &:not(:last-child){
      margin-bottom: 10px;
    }
    &:nth-child(2n-1){
      background-color: #F6FBFF;
    }
    >h3{
      font-size: 17px;
      color: #383838;
      font-weight: bold;
      line-height: 1.5;
      >span{
        color: #E80B10;
      }
    }
    >p{
      font-size: 13px;
      color: #383838;
      line-height: 2;
      margin-top: 10px;
      text-align: justify;
    }
  }
}
</style>