<template>
  <div class="data-value">
    <h3>数智决策·云图 · i-DATA</h3>
    <h4>企业数据价值发现与创新解决方案</h4>
    <p>推动企业数据集中化：打通数据孤岛，整合数据体系，实现数据体系化管控。<br />
    降低数据使用门槛：降低企业数据建设成本，提升数据建设速度，减轻建设难度。<br />
    数据能力变现：企业数据资产管理一目了然，打造持续增值的数字资产，全面激活数据商业价值。<br />
    赋能运营与决策：丰富的数据分析与呈现方式，助力企业关键决策洞察和精细化管理。</p>
    <button @click="openService">立即咨询了解更多详情</button>
  </div>
</template>
<style lang="less" scoped>
.data-value{
  padding: 60px 30px;
  background: url(/static/img/plan-back.jpg) no-repeat center;
  background-size: cover;
  color: #fff;
  >h3{
    // text-transform: uppercase;
    font-size: 18px;
    font-weight: bold;
    text-align: center;
  }
  >h4{
    font-size: 15px;
    font-weight: 300;
    margin-top: 15px;
    text-align: center;
    padding-bottom: 70px;
    position: relative;
    &::after{
      content: '';
      position: absolute;
      bottom: 30px;
      left: 50%;
      width: 15px;
      height: 2px;
      background-color: #fff;
      transform: translateX(-50%);
    }
  }
  >p{
    font-size: 15px;
    font-weight: 300;
    line-height: 2.5;
    text-align: justify;
  }
  >button{
    margin-top: 60px;
    width: 100%;
    border: 0 none;
    background: none;
    appearance: none;
    border-radius: 3px;
    line-height: 42px;
    font-size: 16px;
    font-weight: bold;
    background-color: #E80B10;
    color: #fff;
  }
}
</style>