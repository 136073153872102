<template>
  <div class="intro-item">
    <img :src="item.img" />
    <div class="item-txt">
      <h3>{{ item.title }}</h3>
      <p>{{ item.remark }}</p>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    item: {
      type: Object,
      default: () => { }
    }
  }
}
</script>
<style lang="less" scoped>
.intro-item {
  padding: 10px;
  display: flex;
  align-items: center;
  border: 1px solid #E2E2E2;

  &:not(:last-child) {
    margin-bottom: 20px;
  }

  >img {
    width: 41.6vw;
    display: block;
    margin-right: 18px;
  }

  .item-txt {
    flex: 1;
    color: #383838;
    overflow: hidden;

    >h3 {
      font-size: 16px;
      font-weight: bold;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    >p {
      font-weight: 300;
      font-size: 13px;
      line-height: 1.5;
      margin-top: 10px;
      text-align: justify;
    }
  }
}
</style>