<template>
  <div class="situation-box">
    <h3>企业数据管理现状  ·  situation</h3>
    <p>大多数企业在数据管理、数据运用方面存在以下问题</p>
    <Item v-for="(item,index) in list" :item="item" :key="index"/>
  </div>
</template>
<script>
import Item from '../dingtalk/Item.vue';
export default {
  components:{
    Item
  },
  data() {
    return {
      list:[
        {img:'/static/img/plan-img1.jpg',title:'存储分散且类型多样 ',remark:'数据量大且存储分散，数据类型兼有多结构化、非结构化'},
        {img:'/static/img/plan-img2.jpg',title:'数据标准不一且沉默',remark:'数据标准不一、口径不一且均为沉默数据，未有效挖掘发挥价值'},
        {img:'/static/img/plan-img3.jpg',title:'难以满足业务发展',remark:'业务需求多变化快。现有数据处理难以匹配企业的业务发展'}
      ]
    }
  },
}
</script>
<style lang="less" scoped>
.situation-box{
  padding: 50px 10px;
  color: #383838;
  >h3{
    text-transform: uppercase;
    font-size: 18px;
    color: #383838;
    font-weight: bold;
    text-align: center;
  }
  >p{
    font-size: 14px;
    font-weight: 300;
    margin-top: 10px;
    text-align: center;
    margin-bottom: 40px;
  }
}
</style>