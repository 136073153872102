<template>
  <div class="route-box">
    <h3>企业数字化运营解决方案 · SOLUTIONS</h3>
    <p>应用场景：政府行业、地产行业、教育行业、餐饮行业......</p>
    <img src="/static/img/plan-back2.png"/>
  </div>
</template>
<style lang="less" scoped>
.route-box{
  padding: 50px 25px 100px;
  color: #383838;
  text-align: center;
  background: #F6F6F8;
  >h3{
    font-size: 18px;
    font-weight: bold;
    text-transform: uppercase;
  }
  >p{
    font-size: 15px;
    font-weight: 300;
    margin-top: 10px;
    line-height: 2;
  }
  >img{
    width: 100%;
    display: block;
    margin-top: 50px;
  }
}
</style>